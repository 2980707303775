import { createAsyncThunk } from '@reduxjs/toolkit';
import MenuService from './services/menu.service';
import { ResponseMenuSupply, ResponseUpdateSupply } from './models/menu';
import { StateSupply } from './models/ticket';

export const getStatusByKitchenAndSku = createAsyncThunk<
  ResponseMenuSupply,
  { sku: string; kitchenId: string }
>('ticketManager/getStatusByKitchenAndSku', async ({ sku, kitchenId }) => {
  const data = await MenuService.getProductBySupplyAndKitchen(sku, kitchenId);
  return { data, sku };
});

export const updateStateSupplyThunk = createAsyncThunk<
  ResponseUpdateSupply,
  { sku: string; kitchenId: string; state: StateSupply }
>('ticketManager/updateStateSupplyThunk', async ({ sku, kitchenId, state }) => {
  const data = await MenuService.updateAvailableSupply(sku, kitchenId, state);
  return { data, sku };
});
