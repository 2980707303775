import { configureStore } from '@reduxjs/toolkit';
import appLayoutSlice from '../components/AppLayout/appLayout.slice';
import headerSlice from '../components/AppLayout/components/Header/header.slice';
import sessionSlice from '../routes/components/Session/session.slice';
import ticketManagerSlice from 'pages/TicketManager/ticketManager.slice';

const store = configureStore({
  reducer: {
    appLayout: appLayoutSlice,
    header: headerSlice,
    session: sessionSlice,
    ticketManager: ticketManagerSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
