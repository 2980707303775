import { Env, ENVIRONMENT } from '../config/app';
import Session from '../routes/components/Session/models/session';

export const getImageUrl = (session: Session) => {
  let env = '';
  switch (ENVIRONMENT) {
    case Env.DEVELOPMENT:
      env = '_dev';
      break;

    case Env.STAGING:
      env = '_stg';
      break;
  }
  return `${process.env.REACT_APP_AWS_BUCKECT_URL}/users${env}/${session?.userId}.jpg`;
};

const UserUtil = {
  getImageUrl,
};

export default UserUtil;
