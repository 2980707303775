import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AutomationType, Product, State, Ticket, UserInfo } from './models/ticket';
import Group, { Grouping } from './models/group';
import { getStatusByKitchenAndSku, updateStateSupplyThunk } from './ticketManager.thunk';
import { AsyncState } from './models/types';
import { Chat, MessageChat, PayloadMessageStatus } from './models/chat';

export interface ChannelState {
  id: string;
  tickets: Ticket[];
  page: number;
}

export interface SelectedChannel {
  id: string;
  page: number;
}

export interface TicketManagerState {
  selectedTicket?: Ticket | null;
  selectedChat?: Chat | null;
  playSound: boolean;
  groupsWithNotification: Group[];
  summaryGrouping: Grouping[];
  channels: ChannelState[];
  selectedChannel?: SelectedChannel;
  highlightGroups: string[];
  productsAutomation: Product[];
}

const initialState: TicketManagerState = {
  playSound: false,
  groupsWithNotification: JSON.parse(localStorage.getItem('groupsWithNotification') ?? '[]'),
  summaryGrouping: [],
  channels: [],
  highlightGroups: [],
  productsAutomation: [],
};

const ticketManager = createSlice({
  name: 'ticketManager',
  initialState,
  reducers: {
    setProductsAutomation: (state, products: PayloadAction<Product[]>) => {
      state.productsAutomation = products.payload;
    },
    setSelectedTicket: (state, ticket: PayloadAction<Ticket | null | undefined>) => {
      state.selectedTicket = ticket.payload;
      if (ticket.payload?.products) {
        state.productsAutomation = ticket.payload?.products;
      } else {
        state.productsAutomation = [];
      }
    },
    removeTicketGroupHandle: (
      state,
      action: PayloadAction<{ channelId: string; ticketId: string }>,
    ) => {
      state.channels = state.channels.map((channel) => {
        if (channel.id !== action.payload.channelId) return channel;
        return {
          ...channel,
          tickets: channel.tickets.filter((t) => t.id !== action.payload.ticketId),
        };
      });
    },
    addStateTicket: (state, action: PayloadAction<{ state: State; user: UserInfo }>) => {
      if (!state.selectedTicket) return;
      state.selectedTicket = {
        ...state.selectedTicket,
        state: action.payload.state,
        stateTrace: [
          ...state.selectedTicket.stateTrace,
          {
            createdAt: new Date().toISOString(),
            state: action.payload.state,
            user: {
              id: action.payload.user.id,
              name: action.payload.user.name,
              roles: action.payload.user.roles,
            },
          },
        ],
      };
    },
    setPlaySound: (state, playSound: PayloadAction<boolean>) => {
      state.playSound = playSound.payload;
    },
    setGroupsWithNotification: (state, groups: PayloadAction<Group[]>) => {
      localStorage.setItem('groupsWithNotification', JSON.stringify(groups.payload));
      state.groupsWithNotification = groups.payload;
    },
    setSummaryGrouping: (state, summaryGrouping: PayloadAction<Grouping[]>) => {
      state.summaryGrouping = summaryGrouping.payload;
    },
    setChannels: (state, channels: PayloadAction<ChannelState[]>) => {
      state.channels = channels.payload;

      channels.payload.forEach((channel) => {
        const ticket = channel.tickets.find((ticket) => ticket.id === state.selectedTicket?.id);
        if (ticket) {
          state.selectedTicket = { ...ticket };
          if (ticket.products?.every((product) => product.status !== AsyncState.IDLE)) {
            state.productsAutomation = [...(ticket.products ?? [])];
          }
        }
      });
    },
    setSelectedChannel: (state, selectedChannel: PayloadAction<SelectedChannel>) => {
      state.selectedChannel = selectedChannel.payload;
    },
    setHighlightGroups: (state, highlightGroups: PayloadAction<string[]>) => {
      state.highlightGroups = highlightGroups.payload;
    },
    addMessage: (state, action: PayloadAction<{ message: string; user: UserInfo }>) => {
      if (!state.selectedTicket) return;

      state.selectedTicket = {
        ...state.selectedTicket,
        messages: [
          ...(state.selectedTicket?.messages ?? []),
          {
            user: {
              id: action.payload.user.id,
              name: action.payload.user.name,
              roles: action.payload.user.roles,
            },
            message: action.payload.message,
            createdAt: new Date().toISOString(),
          },
        ],
      };
    },
    toggleRejectProduct: (state, action: PayloadAction<{ product: Product; reject: boolean }>) => {
      if (!state.selectedTicket) return;
      const productUpdated = state.selectedTicket?.products?.map((p) => {
        if (p.sku === action.payload.product.sku) {
          return { ...p, reject: action.payload.reject };
        } else {
          return p;
        }
      });

      state.selectedTicket = {
        ...state.selectedTicket,
        products: [...(productUpdated || [])],
      };
    },
    setSelectedChat: (state, action: PayloadAction<Chat>) => {
      state.selectedChat = action.payload;
    },
    pushMessageChat: (state, action: PayloadAction<MessageChat>) => {
      const isRepeatedMessage = state.selectedChat?.messages.some(
        (message) => message.createdAt === action.payload.createdAt
      );

      if (!isRepeatedMessage && state.selectedChat) {
        state.selectedChat = {
          ...state.selectedChat,
          messages: [...(state.selectedChat?.messages ?? []), action.payload],
        };
      }
    },
    updateMessageStatus: (state, action: PayloadAction<PayloadMessageStatus>) => {
      if (!state.selectedChat) return;

      const messageIndex = state.selectedChat.messages.findIndex(
        (message) => message.createdAt === action.payload.createdAt
      );
      state.selectedChat.messages[messageIndex].status = action.payload.status;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getStatusByKitchenAndSku.pending, (state, action) => {});
    builder.addCase(getStatusByKitchenAndSku.rejected, (state, action) => {});
    builder.addCase(getStatusByKitchenAndSku.fulfilled, (state, action) => {
      const validLogic = (type: AutomationType | null | undefined, available: boolean): boolean => {
        return type === AutomationType.SUPPLY_ON ? available : !available;
      };
      if (state.selectedTicket && state.selectedTicket.products) {
        const skuService = action.payload.sku;
        if (action.payload.data.sku) {
          const available = action.payload.data.available;
          let products = [...state.selectedTicket.products].map((p) => {
            if (p.sku !== skuService) return p;
            if (p.retry > 9) return { ...p, status: AsyncState.INVALIDATE };
            return {
              ...p,
              status: validLogic(state.selectedTicket?.automationType, available)
                ? AsyncState.SUCCESS
                : AsyncState.LOADING,
              retry: p.retry + 1,
            };
          });
          state.selectedTicket = { ...state.selectedTicket, products: products };
        } else {
          let products = [...state.selectedTicket.products].map((p) => {
            if (p.sku !== skuService) return p;
            return {
              ...p,
              status: AsyncState.ERROR,
            };
          });
          state.selectedTicket = { ...state.selectedTicket, products: products };
        }
      }
    });

    builder.addCase(updateStateSupplyThunk.pending, (state, action) => {});

    builder.addCase(updateStateSupplyThunk.fulfilled, (state, action) => {});
  },
});

export const {
  setSelectedTicket,
  setPlaySound,
  setGroupsWithNotification,
  setSummaryGrouping,
  setChannels,
  setSelectedChannel,
  setHighlightGroups,
  addMessage,
  addStateTicket,
  removeTicketGroupHandle,
  toggleRejectProduct,
  setProductsAutomation,
  setSelectedChat,
  pushMessageChat,
  updateMessageStatus
} = ticketManager.actions;
export default ticketManager.reducer;
