import React, { lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Spinner from "../components/Spinner";
import NotFoundPage from "../pages/NotFound";
import AppLayout from "../components/AppLayout";
import Session from "./components/Session";

const TicketManager = lazy(() => import("../pages/TicketManager"));

const routes = (): React.ReactElement => {
  return (
    <BrowserRouter>
      <AppLayout>
        <Session>
          <Routes>
            <Route
              path="/"
              element={<Navigate replace to="/ticket-manager"></Navigate>}
            />
            <Route
              path="/ticket-manager"
              element={
                <Suspense fallback={<Spinner></Spinner>}>
                  <TicketManager />
                </Suspense>
              }
            ></Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Session>
      </AppLayout>
    </BrowserRouter>
  );
};

export default routes;
