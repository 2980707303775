import { MenuItem } from 'alejandria';
import axios from 'axios';
import Session from '../../../routes/components/Session/models/session';

const URL_MODULE_MAP: { [module: string]: string } = {
  'kitchen-staff': process.env.REACT_APP_KITCHEN_STAFF_URL ?? '',
  'kitchen-monitor': process.env.REACT_APP_KITCHEN_MONITOR_URL ?? '',
  'kitchen-display': process.env.REACT_APP_KITCHEN_DISPLAY_URL ?? '',
  'kitchen-inventory': process.env.REACT_APP_KITCHEN_INVENTORY_URL ?? '',
  'menu-manager': process.env.REACT_APP_URL ?? '',
};

const getMenuForCurrentUser = async (session: Session): Promise<MenuItem[]> => {
  const menuManagerModule = 'menu-manager';
  return await axios({
    url: `${process.env.REACT_APP_KITCHEN_DISPLAY_URL}/api/menuByUser`,
    method: 'GET',
    headers: {
      'x-user-id': session.userId,
      'x-auth-token': session.loginToken,
    },
  }).then<MenuItem[]>((response) => {
    const menu: MenuItem[] = response.data;
    return menu.map((menuItem) => {
      if (menuItem.module !== menuManagerModule) {
        menuItem.navigateTo = URL_MODULE_MAP[menuItem.module] + menuItem.navigateTo;
      }
      return menuItem;
    });
  });
};

const MenuService = {
  getMenuForCurrentUser,
};
export default MenuService;
