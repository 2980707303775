import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { KOSMenu, MenuItem } from 'alejandria';
import Box from '@mui/material/Box';

import { useAppSelector } from '../../hooks/useAppSelector';
import Header from './components/Header';
import Spinner from '../Spinner';
import MenuService from './services/menu.service';

interface AppLayoutProps {
  children: React.ReactNode;
}

const AppLayout = (props: AppLayoutProps): React.ReactElement => {
  const navigate = useNavigate();
  const [menu, setMenu] = React.useState<MenuItem[]>([]);
  const showMenu = useAppSelector((state) => state.appLayout.showMenu);
  const session = useAppSelector((state) => state.session.session);

  const handleClickMenuItem = React.useCallback(
    (navigateTo?: string) => {
      if (navigateTo?.includes('http')) {
        window.location.href = navigateTo;
      } else if (navigateTo) {
        navigate(navigateTo);
      }
    },
    [navigate],
  );

  React.useEffect(() => {
    if (session) {
      MenuService.getMenuForCurrentUser(session).then(setMenu);
    }
  }, [session]);

  return (
    <Box display="flex" pt={7}>
      <Header />

      {menu && showMenu && (
        <KOSMenu
          drawerOpen={showMenu}
          items={menu}
          onClickMenuItem={handleClickMenuItem}
          homeUrl={process.env.REACT_APP_URL || '/'}
        />
      )}

      <Box flexGrow={1} minHeight="calc(100vh - 56px)" overflow="auto">
        <Outlet />
        <React.Suspense fallback={<Spinner />}>{props.children}</React.Suspense>
      </Box>
    </Box>
  );
};

export default AppLayout;
