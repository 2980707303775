import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  split,
  ApolloLink,
  Observable,
  DefaultOptions,
} from '@apollo/client';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';

export interface CreateClientOptions {
  httpUrl: string;
  wsUrl: string;
}

export const createGraphQLClient = ({ httpUrl, wsUrl }: CreateClientOptions) => {
  const httpLink = new HttpLink({ uri: httpUrl });

  const wsClient = createClient({
    url: wsUrl,
    shouldRetry: () => true,
  });

  const wsLink = new ApolloLink((operation) => {
    return new Observable((observer) => {
      const unsubscribe = wsClient.subscribe(
        { ...operation, query: operation.query.loc?.source.body ?? '' },
        {
          next: (data: any) => observer.next(data),
          error: (err) => observer.error(err),
          complete: () => observer.complete(),
        },
      );
      return () => unsubscribe();
    });
  });

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
  );

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };
  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions,
  });
};
