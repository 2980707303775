import axios from 'axios';
import { MenuSupply } from '../models/menu';
import { StateSupply } from '../models/ticket';
const getProductBySupplyAndKitchen = (sku: string, kitchenId: string): Promise<MenuSupply> =>
  axios
    .get(
      `${process.env.REACT_APP_API_MENU_MANAGER_URL}/api/v1/menu/manager/${kitchenId}/supply/${sku}/availability`,
    )
    .then((res) => res.data);

const updateAvailableSupply = (sku: string, kitchenId: string, state: StateSupply): Promise<any> =>
  axios
    .post(`${process.env.REACT_APP_API_MENU_MANAGER_URL}/api/v1/menu/manager/supply/availability`, {
      kitchenId: kitchenId,
      sku: sku,
      availability: state,
      user: {
        id: 'System-ticket',
        name: 'System-ticket',
      },
    })
    .then((res) => res.data);
const MenuService = { getProductBySupplyAndKitchen, updateAvailableSupply };

export default MenuService;
