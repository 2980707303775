export enum Env {
  PRODUCTION = "production",
  STAGING = "staging",
  DEVELOPMENT = "development",
}

export const ENVIRONMENT = process.env?.REACT_APP_ENV;


export enum DeliveryPlatform {
  UberEats = 'uber-eats',
  DiDi = 'didi',
  IFood = 'ifood',
  Rappi = 'rappi',
  Justo = 'justo',
  PedidosYa = 'pedidosya',
  PideDirecto = 'pidedirecto',
  TuOrden = 'tuorden',
  MiTienda = 'MITIENDA',
}