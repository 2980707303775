import React from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';

import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import SessionService from '../../../../../../routes/components/Session/services/session.service';
import UserUtil from '../../../../../../utils/user.util';

const UserMenu = (): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const user = useAppSelector((state) => state.session.user);
  const session = useAppSelector((state) => state.session.session);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCLickLogout = () => {
    SessionService.removeSession();
    window.location.href = `${process.env.REACT_APP_KITCHEN_DISPLAY_URL}/redirect?callback=logout`;
  };

  return (
    <>
      {session && user && (
        <Tooltip title="Cuenta">
          <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
            <Avatar
              sx={{ width: 32, height: 32 }}
              src={UserUtil.getImageUrl(session)}
              alt={user.name}
            >
              {user.name.charAt(0)}
            </Avatar>
          </IconButton>
        </Tooltip>
      )}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={onCLickLogout}>
          <ListItemIcon>
            <Icon sx={{ mr: '24px' }}>logout</Icon>
          </ListItemIcon>
          Salir
        </MenuItem>
      </Menu>
    </>
  );
};
export default UserMenu;
