import React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import Package from '../../../../../package.json';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { setShowMenu } from '../../appLayout.slice';
import UserMenu from './components/UserMenu';
import { Env, ENVIRONMENT } from '../../../../config/app';
import Clock from '../../../Clock';

const Header = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const showMenu = useAppSelector((state) => state.appLayout.showMenu);
  const title = useAppSelector((state) => state.header.title);
  const isProduction = ENVIRONMENT === Env.PRODUCTION;

  const handleToggleMenu = React.useCallback(() => {
    dispatch(setShowMenu(!showMenu));
  }, [dispatch, showMenu]);

  return (
    <MuiAppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      color={isProduction ? 'primary' : 'secondary'}
    >
      <Toolbar>
        <Box display="flex" gap={1} alignItems="center" justifyContent="space-between" width="100%">
          <Box className="left-area" display="flex" alignItems="center" flex={1}>
            <IconButton
              size="large"
              edge="start"
              aria-label="menu"
              sx={{ mr: 1, color: '#ffffff' }}
              onClick={handleToggleMenu}
            >
              {showMenu && <CloseIcon />}
              {!showMenu && <MenuIcon />}
            </IconButton>

            <img
              src="https://images-kos.s3.amazonaws.com/logo_Foodology/logo_foodology_blanco.png"
              alt="Foodology logo"
              height={26}
            />

            <Box
              sx={{
                position: 'absolute',
                bottom: 4,
                left: 80,
                display: { xs: 'none', md: 'flex' },
              }}
            >
              <Typography fontSize={10} fontWeight="500" color="white">
                v{Package.version}
              </Typography>
            </Box>
          </Box>

          <Box
            className="center-area"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <Typography variant="h6" textTransform="uppercase" textAlign="center" color="white">
              {title}
            </Typography>
          </Box>

          <Box
            className="right-area"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap={4}
            flex={1}
          >
            <Clock></Clock>
            <UserMenu></UserMenu>
          </Box>
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
};

export default Header;
