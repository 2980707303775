import React from 'react';
import Typography from '@mui/material/Typography';

const Clock = (): React.ReactElement => {
  const getHour = () =>
    new Date().toLocaleTimeString(['en-US'], { hour: '2-digit', minute: '2-digit' });
  const [hour, setHour] = React.useState(getHour());

  React.useEffect(() => {
    const interval = setInterval(() => setHour(getHour()), 1000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <Typography
      variant="h6"
      sx={{ color: 'white', display: 'table-cell', verticalAlign: 'middle' }}
    >
      {hour}
    </Typography>
  );
};

export default Clock;
