import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Routes from './routes';
import store from './store';
import theme from 'config/theme';
import { ApolloProvider } from '@apollo/client';
import { createGraphQLClient } from 'graphql/client';

const client = createGraphQLClient({
  httpUrl: `${process.env.REACT_APP_API_URL}/graphql`,
  wsUrl: `${process.env.REACT_APP_WSS_URL}/subscriptions`,
});

const App = (): React.ReactElement => {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes />
        </ThemeProvider>
      </Provider>
    </ApolloProvider>
  );
};
export default App;
