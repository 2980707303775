export enum AsyncState {
  IDLE = 'IDLE',
  PROCESS = 'PROCESS',
  NOT_PROCESS = 'NOT_PROCESS',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INVALIDATE = 'INVALIDATE',
  FINALLY = 'FINALLY',
  CANCELLED = 'CANCELLED',
}
