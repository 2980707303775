import { DeliveryPlatform } from 'config/app';
import TicketType from './ticketType';
import { AsyncState } from './types';

export enum StateSupply {
  AVAILABLE = 'Available',
  UNAVAILABLE = 'Unavailable',
}
export interface Ticket {
  id?: string;
  type: TicketType;
  automationType: AutomationType | null | undefined;
  state: State;
  stateTrace: StateInfo[];
  kitchen: Kitchen;
  files: File[] | null;
  messages: MessageInfo[];
  products: Product[] | null;
  protocols: string[] | null;
  orders: Order[] | null;
  createdAt: Date;
  turn: number;
  reason?: Reason;
  updatedAt: Date;
  chatId: string;
}

export enum AutomationType {
  SUPPLY_ON = 'SUPPLY_ON',
  SUPPLY_OFF = 'SUPPLY_OFF',
  WITH_RT = 'WITH_RT',
  MANUAL = 'MANUAL',
}

export interface Order {
  universalId: string;
  platform: DeliveryPlatform;
  brandId?: string;
}

export interface Reason {
  name: string;
  type: ReasonType;
}

export enum ReasonType {
  STOCKOUT = 'STOCKOUT',
  CAN_NOT_PREPARE = 'CAN_NOT_PREPARE',
  PROVIDER_NOT_ARRIVED = 'PROVIDER_NOT_ARRIVED',
  PRODUCT_DAMAGED = 'PRODUCT_DAMAGED',
  DOMICILIARY_ASSIGNED_AND_NOT_ARRIVED = 'DOMICILIARY_ASSIGNED_AND_NOT_ARRIVED',
  DOMICILIARY_IS_NOT_IN_STORE = 'DOMICILIARY_IS_NOT_IN_STORE',
  WITHOUT_DOMICILIARY_ASSIGNED = 'WITHOUT_DOMICILIARY_ASSIGNED',
  REPORT_DELIVERY = 'REPORT_DELIVERY',
}

export enum ReasonRejectDescription {
  SUPPLY_DEPLOY = 'Es insumo de lanzamiento',
  SUPPLY_NOT_AUTHORIZE = 'Es insumo y no fue autorizado',
  SUPPLY_MANY_OFF = 'El insumo apaga demasiados productos del menú y necesita previa autorización',
  IN_TRANSIT = 'Compras informó que el insumo va en camino',
}

interface File {
  url: string;
  name: string;
  createdAt: Date;
}

interface Kitchen {
  kitchenId: string;
  name: string;
}

interface StateInfo {
  state: State;
  user: User;
  createdAt: Date | string;
}

interface User {
  id: string;
  name: string;
  roles: string[];
}

export enum State {
  TO_DO = 'TO_DO',
  DOING = 'DOING',
  DONE = 'DONE',
}

export interface MessageInfo {
  message: string;
  user: User;
  createdAt: string;
}
export interface Product {
  sku: string;
  name: string;
  country: string;
  category?: string | null;
  status: AsyncState;
  retry: number;
  processAt?: string | null;
  reject?: boolean | null;
  rejectMessage?: string | null;
}

export interface UpdatePayload {
  ticketId: string;
  state?: State | null;
  user: UserInfo;
  messages?: string[];
  products?: Product[];
}

export interface UserInfo {
  id: string;
  name: string;
  roles: string[];
}
